<template lang="pug">
    Dialog.buscarProcedimento(header='Pesquisar procedimento' @hide='clearData()' :visible.sync='$parent.model.dialogProcedimento' :modal='true')
        
        .ta-center(v-if='waiting')
            ProgressSpinner(strokeWidth='3')

        form(v-else @submit.prevent='buscarProcedimento()')
            .p-grid.p-fluid.p-align-start
                .p-col-12.p-md-12
                    .p-grid.p-fluid.p-align-end
                        .p-col-12.p-md-12
                            label.form-label Tipo de procedimento:
                            Dropdown(appendTo="body" v-model='filters.tipo_item' :options='options.tipo_item' 
                                dataKey='value' optionLabel='label' optionValue='value' filter)
                        .p-col-12.p-md-6(v-if='(["PE", "C"]).includes(filters.tipo_item)')
                            label.form-label Estabelecimento:
                            Dropdown( appendTo="body"  v-model='$parent.model.estabelecimentos_selecionados' 
                            :options='$parent.options.estabelecimentos' dataKey='id' optionLabel='nm_fantasia' optionValue='id' 
                            filter @change='onEstabChange()')
                        
                        .p-col-12.p-md-6(v-if='filters.tipo_item == "PE"')
                            label.form-label Procedimento:
                            InputText(type='text'  v-model='$parent.model.procedimentos_selecionado' @input="onProcedChange()")
                        
                        .p-col-12.p-md-6(v-if='filters.tipo_item == "C"')
                            label.form-label Especialidade:
                            Dropdown(appendTo="body" :disabled="!$parent.model.estabelecimentos_selecionados" v-model='filters.cd_especialidade' :options='optionsEspecialidades' 
                            dataKey='value' optionLabel='text' optionValue='value' filter)
                        
                        .p-col-12.p-md-12(v-if='filters.tipo_item == "C"')
                            label.form-label Agendas:
                            MultiSelect(
                            appendTo="body"
                            v-model='filters.cd_agenda'
                            :disabled = '!filters.cd_especialidade'
                            :options='options.cd_agenda' 
                            dataKey='value'
                            optionLabel='text' 
                            optionValue='value')

            ProgressBar(v-if='waitingProcedimentos && filters.tipo_item == "PE"' mode="indeterminate")
            .p-col-12(v-else-if='procedimentos.length && filters.tipo_item == "PE"')
                DataTable(style='max-height:360px;overflow-y:auto' :value='procedimentos')
                    Column(header='Procedimentos' field='label' bodyStyle='padding: 0')
                        template(#body='props')
                            .pa-1(style='cursor:pointer;font-size:12px;display:flex;align-items:center' @click='selecionarProcedimento(props.data)')
                                .mr-1(style='display:inline;color:blue;font-weight:700' v-if="$parent.model.itemList.findIndex(item => item.id == props.data.value || item.cd_cbhpm == props.data.value || (item.itensId && item.itensId.find(i => i.idProcedEstab == props.data.value))) != -1")
                                    i.jam.jam-check
                                .mr-1(style='display:inline;color:red;font-weight:700' v-else)
                                    i.jam.jam-close
                                span {{ props.data.label }}
           
            .p-col-12(v-else-if='consultas.length && filters.tipo_item == "C"')
                DataTable(style='max-height:360px;overflow-y:auto' :value='consultas')
                    Column(header='Consultas' field='label' bodyStyle='padding: 0')
                        template(#body='props')
                            .pa-1(style='cursor:pointer;font-size:12px;display:flex;align-items:center' @click='selecionarProcedimento(props.data)')
                                //- .mr-1(style='display:inline;color:blue;font-weight:700' v-if="$parent.model.itemList.findIndex(item => item.id == props.data.value || item.cd_cbhpm == props.data.value || (item.itensId && item.itensId.find(i => i.idProcedEstab == props.data.value))) != -1")
                                //-     i.jam.jam-check
                                //- .mr-1(style='display:inline;color:red;font-weight:700' v-else)
                                //-     i.jam.jam-close
                                span {{ props.data.label }}
           
           
            //- ProgressBar(v-if='waitingConsultas' mode="indeterminate")
            //- .p-col-12(v-else-if='consultas.length && filters.tipo_item == "C"')
            //-     DataTable.carrinho-datatable( @row-select='model.cd_estabelecimento = $event.data.cd_estabelecimento'
            //-         :value='options.estabelecimentos' dataKey='id' :selection.sync="selectedEstabelecimento")
            //-         Column(headerStyle='width:7%' selectionMode='single' bodyStyle='text-align: center;')
            //-         Column(headerStyle='width:50%' header='Estabelecimento')
            //-             template(#body='props')
            //-                 p.aux1
            //-                     i(v-if="props.data.possui_integracao == true" style="color:#db7900" class="jam jam-bookmark-plus-f") &nbsp;
            //-                     b {{ props.data.nm_estabelecimento }}
            //-                     span(v-if='props.data.ds_endereco')  - {{ props.data.ds_endereco }}, {{ props.data.nr_telefone }}
            //-                 p.aux1(v-if='props.data.ds_observacao')
            //-                     b  {{ props.data.ds_observacao }}
            //-                 p.aux1(v-if='props.data.cd_codigo_proprio') <b>Cód. próprio: {{ props.data.cd_codigo_proprio }}</b>
            //-                 p.aux1.aux2(:class="{ restrict: props.data.ie_restrito }" style='white-space: pre-wrap' v-if='props.data.ds_informacoes') {{ props.data.ds_informacoes }}
            //-                 div(v-if='props.data.procedimentos')
            //-                     p(v-if='props.data.procedimentos') Lista de exames ( {{ calcExamesDisponiveis(props.data.procedimentos) }}  / {{ props.data.procedimentos.length }}&nbsp;)
            //-                         Button.small.p-button-rounded.p-button-outlined(v-if='verificaObservacoes(props.data.procedimentos)' icon="jam jam-info" type="button" @click="dialogObservacoes.display = true; dialogObservacoes.procedimentos = props.data.procedimentos")
            //-                         span(v-for='item, idx in props.data.procedimentos' :key='idx' :class="{'item-indisponivel': item.disponivel == false}")
            //-                             br
            //-                             b {{ idx+1 }}. &nbsp;
            //-                             span {{ item.ds_observacao }} &nbsp;
            //-                             span(v-if='item.disponivel == true') {{ formatPrice(item.nr_valor) }}
            //-                             span(v-if='!item.id') (Indisponível)
            //-                             span(v-if='item.ie_restrito') (Suspenso)
            //-         Column(headerStyle='width:15%' field='nr_valor_f' bodyStyle='text-align:center' header='Valor')
            //-             template(#body='props')
            //-                 span.p-mr-1 {{ props.data.nr_valor_f }}
            //-                 Button.small.p-button-rounded.p-button-outlined.p-button-success(v-tooltip.top="'Adicionar no orçamento'" icon="jam jam-document" type="button" @click='adicionarOrcamento(props.data)')

</template>

<style lang="scss">
.p-multiselect-panel {
    position: absolute;
    z-index: 9999;
    height: auto;
    width:15%;
}
.p-dropdown-panel {
    width:15%;
    position: absolute;
    z-index: 9999 !important;
    height: auto;
}
.buscarProcedimento{
    width: 100%;
    max-width: 800px;
    .p-dialog-content {
        max-height: 540px;
        // overflow-y: auto !important;
        // min-height: 330px;
    }
    .p-progress-spinner {
        width: 3.2em;
        height: auto;
        margin: 8em 0;
    }   
   
    .carrinho-datatable {
        max-height: 500px;
        overflow-y: auto;
        .cell {
            padding: 16px 0;
            text-align: center;
            background-color: #e5e5e5;
            &.on  { background-color: #e4f8e1; }
            &.off  {
                color: #FFF;
                font-size: 16px;
                font-weight: 700;
                background-color: #ef0000;
            }
        }
        .p-highlight .cell {
            background-color: #007ad9 !important;
        }
        .cell-procedimento-checkup {
            margin: 0;
            &.strike {
                text-decoration: line-through;
            }
        }
    }
}

</style>

<script>
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import ProgressSpinner from "primevue/progressspinner";
import Dropdown from "primevue/dropdown";
import CustomMultiSelect from "../CustomComponents/CustomMultiSelect.vue";
import ProgressBar from 'primevue/progressbar';
import { Procedimentos, ProcedimentosEstabelecimento, Pacote, Agenda } from "../../middleware";

export default {
    components: {
        Dialog,
        InputText,
        Button,
        DataTable,
        Column,
        ProgressSpinner,
        MultiSelect,
        Dropdown,
        CustomMultiSelect,
        ProgressBar
    },
    created() {
        this.$parent.model.estabelecimentos_selecionados = [];
    },
    watch: {
        "$parent.model.estabelecimentos_selecionados": function(val){
            if(val.length > 0 && this.filters.tipo_item == "C"){
                if(!this.filters.cd_especialidade) this.buscarConsultas()
                else{
                    this.buscarAgenda()
                    this.buscarConsultas()
                }
            }
        },
        "$parent.model.dialogProcedimento": function() {
            this.procedimentos = [];
            this.filters.ds_procedimento = "";
        },
        "filters.tipo_item": function() {
            // if(val == "C")
                this.$parent.model.estabelecimentos_selecionados = [];
                this.procedimentos = [];
                this.consultas = [];
        },
        "filters.cd_especialidade": function(val){
            this.filters.cd_agenda = [];
            if(val) {
                this.buscarAgenda()
                this.buscarConsultas()
            }
            
        },
        "filters.cd_agenda": function(val){
            if(val.length > 0) this.buscarConsultas()
        },
        procedimentos_selecionado: function() {
            this.onProcedChange();
        }
    },
    props: ['optionsEspecialidades'],
    data() {
        return {
            procedimentos: [],
            consultas: [],
            todas_consultas: [],
            todos_estabelecimentos: [],
            estabelecimentos: [],
            waiting: false,
            waitingProcedimentos: false,
            waitingConsultas: false,
            filters: { 
                ds_procedimento: "",
                tipo_item: null,
                cd_especialidade: null,
                cd_agenda: []
            },
            options: {
                tipo_item: [
                    { label: "Consulta", value: "C" },
                    { label: "Procedimento ou Exame", value: "PE" }
                ],
                cd_agenda: []
            }
            }
    },
    methods: {
        async onEstabChange() {
            if(this.filters.tipo_item == "PE"){
                this.waitingProcedimentos = true;
                if (this.$parent.model.multi_estabelecimento) {
                    const params = {
                        cd_estabelecimento: this.$parent.model
                        .estabelecimentos_selecionados,
                        order: "cd_cbhpm",
                        paginacao: false
                    };
                    await ProcedimentosEstabelecimento.findAll(params)
                        .then(response => {
                            this.waitingProcedimentos = false;
                            if (([200,201]).includes(response.status)) {
                                this.$parent.model.todos_procedimentos =
                                    response.data;
                                this.procedimentos = response.data.filter(item => item.cd_cbhpm != "10101012" && item.ie_tipo_procedimento != "CO").map(
                                    item => ({
                                        label: `${item.cd_cbhpm} - ${item.nm_procedimento}`,
                                        value: item.id
                                    })
                                );
                            } 
                        })
                        .catch(error => console.log("ERROR", error));
                } else {
                    if (
                        this.$parent.model.estabelecimentos_selecionados.length > 0
                    ) {
                        const params = this.$parent.model.estabelecimentos_selecionados
                            .map(item => item.toString())
                            .join(",");
    
                        await ProcedimentosEstabelecimento.findAllByPrice(
                            params
                        ).then(response => {
                            if (response.status == 200) {
                                this.$parent.model.todos_procedimentos =
                                    response.data;
                                this.procedimentos = response.data.filter(item => item.cd_cbhpm != "10101012" && item.ie_tipo_procedimento != "CO").map(item => ({
                                    label:
                                        item.cd_cbhpm +
                                        " - " +
                                        item.nm_procedimento,
                                    value: item.cd_cbhpm
                                }));
                            }
                        });
                    } else {
                        this.$parent.model.todos_procedimentos = [];
                        this.procedimentos3 = [];
                    }
                }
            }else this.filters.cd_especialidade = ""
        },
        onProcedChange() {
            const auxItems = [];
            this.$parent.model.todos_procedimentos.forEach(item => {
                if (!this.$parent.model.multi_estabelecimento) {
                    if (
                        item?.nm_procedimento
                            .toLowerCase()
                            .includes(
                                this.$parent.model?.procedimentos_selecionado.toLowerCase()
                            ) ||
                        item?.cd_cbhpm
                            .toLowerCase()
                            .includes(
                                this.$parent.model?.procedimentos_selecionado.toLowerCase()
                            )
                    ) {
                        auxItems.push({
                            label: `${item.cd_cbhpm} - ${
                                item.cd_cbhpm == "10101012"
                                    ? item.ds_procedimento
                                    : item.nm_procedimento
                            }`,
                            value: this.$parent.model.multi_estabelecimento
                                ? item.id
                                : item.cd_cbhpm
                        });
                    }
                } else {
                    if (
                        item?.nm_procedimento
                            .toLowerCase()
                            .includes(
                                this.$parent.model?.procedimentos_selecionado.toLowerCase()
                            ) ||
                        item?.cd_cbhpm
                            .toLowerCase()
                            .includes(
                                this.$parent.model?.procedimentos_selecionado.toLowerCase()
                            ) ||
                        (item.cd_cbhpm == "10101012" &&
                            item?.ds_procedimento
                                .toLowerCase()
                                .includes(
                                    this.$parent.model?.procedimentos_selecionado.toLowerCase()
                                ))
                    ) {
                        auxItems.push({
                            label: `${item.cd_cbhpm} - ${
                                item.cd_cbhpm == "10101012"
                                    ? item.ds_procedimento
                                    : item.nm_procedimento
                            }`,
                            value: this.$parent.model.multi_estabelecimento
                                ? item.id
                                : item.cd_cbhpm
                        });
                    }
                }
            });
            this.procedimentos = auxItems;
        },
        buscarProcedimento() {
            if (this.filters.ds_procedimento) {
                this.procedimentos = [];
                this.waiting = true;

                Procedimentos.findAllEstabelecimentos({
                    ds_procedimento: this.filters.ds_procedimento
                }).then(response => {
                    this.waiting = false;
                    if (response.status === 200) {
                        if (response.data.length)
                            this.procedimentos = response.data;
                        else
                            this.$toast.error(
                                `Nenhum procedimento encontrado`,
                                { duration: 3000 }
                            );
                    }
                });
            } else
                this.$toast.info(
                    `Digite o nome do procedimento para a pesquisa`,
                    { duration: 3000 }
                );
        },
        selecionarProcedimento(procedimento) {
            
            const index = this.$parent.model.itemList.findIndex(item => item.id == procedimento.value);
            if (index == -1 || this.filters.tipo_item == "C") {
                const procAux = this.filters.tipo_item == "PE"?  
                    this.$parent.model.todos_procedimentos.find( item => item.id == procedimento.value)
                    : 
                    this.todas_consultas.find(item => item.id == procedimento.value)
                this.$parent.model.itemList.push({
                    nm_estabelecimento: procAux.nm_estabelecimento,
                    cd_cbhpm: procAux.cd_cbhpm,
                    nm_procedimento: `${procAux.cd_cbhpm} - ${
                        procAux.cd_cbhpm == "10101012"
                            ? procAux.ds_procedimento
                            : procAux.nm_procedimento
                    }`,
                    qtd: 1,
                    valor_original:
                        procAux.nr_valor_negociado +
                        procAux.nr_valor_tarifa,
                    nr_valor_negociado: procAux.nr_valor_negociado,
                    nr_valor_tarifa: procAux.nr_valor_tarifa,
                    id: procAux.id,
                    ie_grupo: procAux.ie_grupo,
                    cd_agenda: this.filters.cd_agenda,
                    cd_agenda_f: this.filters.cd_agenda.map(item => (this.options.cd_agenda.find(i => i.value == item)))
                });
            } else {
                this.$parent.model.itemList.splice(index, 1);
            } 
            this.filters.cd_agenda = []
        },
        async buscarConsultas(){
            let params ={
                cd_especialidade: this.filters.cd_especialidade,
                cd_agenda: this.filters.cd_agenda,
                cd_estabelecimento: this.$parent.model.estabelecimentos_selecionados
            }
            try{
                let response = await Pacote.buscarConsultas(params)
                if(([200,201,204]).includes(response.status)){
                    this.todas_consultas = response.data;
                    this.consultas = response.data.map(item => ({
                        label: `${item.nm_procedimento} - ${this.$root.formatPrice(item.nr_valor)}`,
                        value: item.id
                    }))
                }
            }catch(e){
                console.log(e)
            }
        },
        async buscarAgenda(){
            let params = {
                paginacao:false,
                // order:"cd_especialista__nm_especialista",
                ie_tipo_agenda: "C",
                cd_procedimento_especialidade: this.filters.cd_especialidade,
                cd_especialidade: this.filters.cd_especialidade,
                cd_estabelecimento: this.$parent.model.estabelecimentos_selecionados,
                // ignorarEstabelecimento: true
                // ie_atende_medclub_empresa: false
            }
            try{
                this.waitingAgenda = true;
                const response = await Agenda.findAllPacote(params)
                this.waitingAgenda = false;
                if(([200,201,204]).includes(response.status)){
                    this.options.cd_agenda = response.data.results.map(item => ({
                        value: item.id,
                        text: item.nm_especialista
                    }))
                }
            }catch(e){
                console.log(e)
            }
         
        },
        clearData(){
            this.procedimentos = [],
            this.consultas = [],
            this.todas_consultas = [],
            this.todos_estabelecimentos = [],
            this.estabelecimentos = [],
            this.filters = { 
                ds_procedimento: "",
                tipo_item: null,
                cd_especialidade: null,
                cd_agenda: []
            },
            this.options = {
                tipo_item: [
                    { label: "Consulta", value: "C" },
                    { label: "Procedimento ou Exame", value: "PE" }
                ],
                cd_agenda: []
            }
        }
    }
};
</script>
